body{font-family: 'Poppins', sans-serif;color: #000000;font-size: 16px !important;background: #edf4fd !important;}
::selection {color: #FFF;background: #345fb0;}
button, input, optgroup, select, textarea {font-family: 'Poppins', sans-serif !important;}
body h1, body h2, body h3, body h4, body h5, body h6{font-weight: 500;}
body h4, body .h4 {font-size: 24px;}
.cursor-pointer {cursor: pointer;} 
.daysLeft{color: #ffffff !important;}
.leftSidebar {width: 17%;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;overflow-y: auto;overflow-x: hidden;}
.rightSidebar {width: 17%;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}
.rightWrap{background: url(../images/rightSidebar.jpg) no-repeat;background-size: cover;background-position: bottom center;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;width: 17%;padding: 20px 20px 100px;position: fixed;height: 100%; overflow-x: hidden;overflow-y: auto;}
.premiumUser{color: #1c3563;font-size: 14px; border-radius: 30px; background: rgb(255,232,161);background: -moz-radial-gradient(circle, rgba(255,232,161,1) 0%, rgba(236,187,59,1) 100%);background: -webkit-radial-gradient(circle, rgba(255,232,161,1) 0%, rgba(236,187,59,1) 100%);background: radial-gradient(circle, rgba(255,232,161,1) 0%, rgba(236,187,59,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffe8a1",endColorstr="#ecbb3b",GradientType=1);}
.menuItem {transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;display: flex;flex-direction: column;margin-bottom: 1px;}
.menuItem a {color: #fff;text-decoration: none;display: flex;align-items: center;padding: 8px 8% 8px 6%;border-radius: 0 30px 30px 0;align-self: flex-start;font-size: 14px;}
.menuText {text-transform: uppercase;padding-left: 10px;}
.menuIcon img {height: 20px;}
.siteWrap {display: flex;justify-content: space-between;}
.siteContent, .content {width: calc(100% - 36%);transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}
.menuItem a:hover, a.menuCurrent, .menuItem a:focus{color: #6a5cf7;background: #b9ed2e;text-decoration: none;transition: 0.5s;}

.menuItem a.dropdown-item {font-size: 14px;padding: 5px 10px;border-radius: 0;}
.menuItem a.dropdown-item:hover	{background: transparent;color: #fff;opacity: .9;}
.siteWrap h3{font-size: 19px;font-weight: 500;}
.siteWrap h3.fw-bold{font-weight: 700;}
.statBox {display: flex;justify-content: space-between;border-radius: 20px;padding: 20px;font-size: 15px;font-weight: 600;}
.greenBox{background: #E05263;color: #ffffff;}
.sheen-gold{background: #c79c33 !important;color: #ffffff;}
.sheen-gold__text{color: #c79c33 !important;}
.skuBlueBox{background: #55c1ff;color: #ffffff;}
.totalMocks {background: #fff;border-radius: 20px;display: flex;flex-direction: column;justify-content: center;padding:10px 15px;text-align: center;font-size: 22px;color: #E05263;font-weight: 600;box-shadow: 0 10px 10px rgba(0,0,0,.1);}
.totalMocks b {font-size: 34px;font-weight: 600;}

.daysValue, .practicedValue, .desiredValue {font-size: 38px;font-weight: 600;}
.examDate {width: 38%;}
.desiredScore {padding: 0 4%;border-left: 2px solid;border-right: 2px solid;}
.statsWrap{position: relative;}
.statsWrap:before {content: "";width: 2px;height: 100%;background: #b5b5b5;position: absolute;left: -5%;}
.goalsColumn a {background: #fff;padding: 10px 20px;width: 100%;text-align: center;text-decoration: none;color: #4f4f4e;border-radius: 15px;font-size: 18px;box-shadow: 0 10px 10px rgba(0,0,0,.1);transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}
.goalsColumn a:hover {background: #345fb0;color: #fff;}
.cursor-pointer:hover {text-decoration: underline;}
/*.questionsPracticed {
    border-right: 2px solid #fff;
}*/
.card.cardBox {border-radius: 30px;border: none;}
.cardBlock .detailCard {border-radius: 20px;padding: 20px;color: #fff;}
.cardBlock .detailCard a{text-decoration: none;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}
.cardBlock .detailCard a:hover {border-bottom: 1px solid;}
.cardBlock .cardSpeaking {background: #4f4f4e;}
.cardBlock .cardWriting {background: #b0117a;}
.cardBlock .cardReading {background: #b5c004;}
.cardBlock .cardListening {background: #0659b2;}
.cardBlock .detailCard small {opacity: .8;font-weight: 500;}
.cardBlock .detailCard small a {text-decoration: underline;}
.cardBlock .detailCard small a:hover {text-decoration: none;opacity:0.7;transition: 0.5s ease all;}
.totorialTabs .nav-link {color: #878787;border-radius: 0 !important;}
.classBox {background: rgba(255,255,255,.4);border-radius: 20px;color: #fff;}
.classBox li {font-size: 14px;display: flex;justify-content: space-between;padding: 5px 0;}
.classBox li a{color: #ffffff;text-decoration: none;}
.classBox li a:hover {color: #2136b5;}
.classBox li span {padding-left: 10px;}
.classBox h4 {background: rgba(255,255,255,.5);color: #000;font-size: 18px;text-align: center;padding: 10px 10px;border-radius: 20px 20px 0 0;}
.classBox ul{padding: 10px 15px 20px;}
.siteContent .card {
    margin: 0;
}
.checkGlobalPage .siteContent .card {
    height: 100%;
}
.anotherPlan {background: #2136b5;color: #fff !important;width: 100%;padding: 6px 10px;font-size: 14px;float: left;border-radius: 12px;text-align: center;text-decoration: none;margin: 20px 0 0 0;
transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}
.anotherPlan:hover{background: #08acae;}
.borderTitle:after {content: "";height: 2px;width: 100%;background: #b1b1b1;position: absolute;top: 11px;}
.borderTitle h3 {background: #fff;position: relative;z-index: 1;padding-right: 30px;}
.borderTitle {display: flex;position: relative;}
.borderTitle h3.bg-grey {background: #edf4fd;}
.crownIcon{position: relative;}
.menuText.crownIcon:after {content: "";height: 22px;width: 22px;background: url(../images/crown.svg);float: right;background-size: 100% auto;margin: 0 0 0 10px;}
.menuItem .dropdown-menu {position: static !important;transform: none !important;background: transparent !important;border: none;padding: 8px 15%;}
/*.openSidebar {left: 1%;background: #1c3563;padding: 0;border-radius: 100%;height: 35px;width: 35px;text-align: center;top: 22px;cursor: pointer;}
.openSidebar img {height: 20px;}*/
.openSidebar .leftSidebar {
    display: flex;
    align-items: center;
    justify-content: center;
}
.scoreImg {text-align: center;width: 90%;}
.toggleIcon{cursor: pointer;}
.menuIcon {height: 20px;width: 20px;background-size: 100% auto !important;}
.helpBtn {background: rgb(158, 192, 59);padding: 6px 20px;border-radius: 12px;color: #fff;text-decoration: none;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;font-size: 14px;}
.helpBtn:hover {background: rgb(194, 230, 89);color: #fff;}
.singUpBtn {background: #01c0e7;padding: 6px 20px;border-radius: 12px;color: #fff;text-decoration: none;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;font-size: 14px;}
.singUpBtn:hover {background: rgb(105, 207, 249);color: #fff;}
.scoreValue {position: absolute;top: 0;left: 0;font-size: 96px;font-weight: 600;height: 90%;width: 100%;display: flex;align-items: center;justify-content: center;color: #454343;}

.menuIcon1{background: url(../images/icon_1.svg);}
.menuIcon2{background: url(../images/icon_2.svg);}
.menuIcon3{background: url(../images/icon_3.svg);}
.menuIcon4{background: url(../images/icon_4.svg);}
.menuIcon5{background: url(../images/icon_5.svg);}
.menuIcon6{background: url(../images/icon_6.svg);}
.menuIcon7{background: url(../images/icon_7.svg);}
.menuIcon8{background: url(../images/icon_8.svg);}
.menuIcon9{background: url(../images/icon_9.svg);}
.menuIcon10{background: url(../images/icon_10.svg);}
.menuIcon11{background: url(../images/icon_11.svg);}
.menuIcon12{background: url(../images/icon_12.svg);}
.menuIcon13{background: url(../images/icon_13.svg);}
.menuIcon14{background: url(../images/notification.svg);}

.menuItem1:hover .menuIcon1 , .menuItem1 a:focus .menuIcon1 , .menuItem1 .menuCurrent .menuIcon1{background: url(../images/icon_1_active.svg);}
.leftSidebarClosed .menuItem1:hover .menuIcon1 , .leftSidebarClosed .menuItem1 a:focus .menuIcon1 , .leftSidebarClosed .menuItem1.menuCurrent .menuIcon1{background: url(../images/icon_1b_active.svg);}

.menuItem2:hover .menuIcon2 , .menuItem2 a:focus .menuIcon2 , .menuItem2 .menuCurrent .menuIcon2{background: url(../images/icon_2_active.svg);}
.leftSidebarClosed .menuItem2:hover .menuIcon2 , .leftSidebarClosed .menuItem2 a:focus .menuIcon2 , .leftSidebarClosed .menuItem2 .menuCurrent .menuIcon2{background: url(../images/icon_2b_active.svg);}

.menuItem3:hover .menuIcon3 , .menuItem3 a:focus .menuIcon3 , .menuItem3 .menuCurrent .menuIcon3{background: url(../images/icon_3_active.svg);}
.leftSidebarClosed .menuItem3:hover .leftSidebarClosed .menuIcon3 , .leftSidebarClosed .menuItem3 a:focus .menuIcon3 , .leftSidebarClosed .menuItem3 .menuCurrent .menuIcon3{background: url(../images/icon_3b_active.svg);}

.menuItem4:hover .menuIcon4 , .menuItem4 a:focus .menuIcon4 , .menuItem4 .menuCurrent .menuIcon4{background: url(../images/icon_4_active.svg);}
.leftSidebarClosed .menuItem4:hover .menuIcon4 , .leftSidebarClosed .menuItem4 a:focus .menuIcon4 , .leftSidebarClosed .menuItem4 .menuCurrent .menuIcon4{background: url(../images/icon_4b_active.svg);}

.menuItem5:hover .menuIcon5 , .menuItem5 a:focus .menuIcon5 , .menuItem5 .menuCurrent .menuIcon5{background: url(../images/icon_5_active.svg);}
.leftSidebarClosed .menuItem5:hover .menuIcon5 , .leftSidebarClosed .menuItem5 a:focus .menuIcon5 , .leftSidebarClosed .menuItem5 .menuCurrent .menuIcon5{background: url(../images/icon_5b_active.svg);}

.menuItem6:hover .menuIcon6 , .menuItem6 a:focus .menuIcon6 , .menuItem6 .menuCurrent .menuIcon6{background: url(../images/icon_6_active.svg);}
.leftSidebarClosed .menuItem6:hover .menuIcon6 , .leftSidebarClosed .menuItem6 a:focus .menuIcon6 , .leftSidebarClosed .menuItem6 .menuCurrent .menuIcon6{background: url(../images/icon_6b_active.svg);}

.menuItem7:hover .menuIcon7 , .menuItem7 a:focus .menuIcon7 , .menuItem7 .menuCurrent .menuIcon7{background: url(../images/icon_7_active.svg);}
.leftSidebarClosed .menuItem7:hover .menuIcon7 , .leftSidebarClosed .menuItem7 a:focus .menuIcon7 , .leftSidebarClosed .menuItem7 .menuCurrent .menuIcon7{background: url(../images/icon_7b_active.svg);}

.menuItem8:hover .menuIcon8 , .menuItem8 a:focus .menuIcon8 , .menuItem8 .menuCurrent .menuIcon8{background: url(../images/icon_8_active.svg);}
.leftSidebarClosed .menuItem8:hover .menuIcon8 , .leftSidebarClosed .menuItem8 a:focus .menuIcon8 , .leftSidebarClosed .menuItem8 .menuCurrent .menuIcon8{background: url(../images/icon_8b_active.svg);}

.menuItem9:hover .menuIcon9 , .menuItem9 a:focus .menuIcon9 , .menuItem9 .menuCurrent .menuIcon9{background: url(../images/icon_9_active.svg);}
.leftSidebarClosed .menuItem9:hover .menuIcon9 , .leftSidebarClosed .menuItem9 a:focus .menuIcon9 , .leftSidebarClosed .menuItem9 .menuCurrent .menuIcon9{background: url(../images/icon_9b_active.svg);}

.menuItem10:hover .menuIcon10 , .menuItem10 a:focus .menuIcon10 , .menuItem10 .menuCurrent .menuIcon10{background: url(../images/icon_10_active.svg);}
.leftSidebarClosed .menuItem10:hover .menuIcon10 , .leftSidebarClosed .menuItem10 a:focus .menuIcon10 , .leftSidebarClosed .menuItem10 .menuCurrent .menuIcon10{background: url(../images/icon_10b_active.svg);}

.menuItem11:hover .menuIcon11 , .menuItem11 a:focus .menuIcon11 , .menuItem11 .menuCurrent .menuIcon11{background: url(../images/icon_11_active.svg);}
.leftSidebarClosed .menuItem11:hover .menuIcon11 , .leftSidebarClosed .menuItem11 a:focus .menuIcon11 , .leftSidebarClosed .menuItem11 .menuCurrent .menuIcon11{background: url(../images/icon_11b_active.svg);}

.menuItem12:hover .menuIcon12 , .menuItem12 a:focus .menuIcon12 , .menuItem12 .menuCurrent .menuIcon12{background: url(../images/icon_12_active.svg);}
.leftSidebarClosed .menuItem12:hover .menuIcon12 , .leftSidebarClosed .menuItem12 a:focus .menuIcon12 , .leftSidebarClosed .menuItem12 .menuCurrent .menuIcon12{background: url(../images/icon_12b_active.svg);}

.menuItem13:hover .menuIcon13 , .menuItem13 a:focus .menuIcon13 , .menuItem13 .menuCurrent .menuIcon13{background: url(../images/icon_13_active.svg);}
.leftSidebarClosed .menuItem13:hover .menuIcon13 , .leftSidebarClosed .menuItem13 a:focus .menuIcon13 , .leftSidebarClosed .menuItem13 .menuCurrent .menuIcon13{background: url(../images/icon_13b_active.svg);}

.leftSidebarClosed .menuItem a:hover, .leftSidebarClosed a.menuCurrent, .leftSidebarClosed .menuItem a:focus{background: transparent;}

.avatarImg {height: 114px;width: 114px;background: #1c3563;border-radius: 100%;margin: 0 auto;border: 7px solid #b9ed2e;position: relative;display: flex;align-items: center;justify-content: center;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}
.crownImg {position: absolute;right: -10px;top: -10px;height: 40px;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}
.userAvatar {border-radius: 100%;height: 101px;width: 101px;object-fit: cover;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}


.siteBlue{color: #345fb0 !important;}
.siteBlueBtn{background: #6c99ed;}
.br-15{border-radius:15px;}
.br-10{border-radius:10px;}
.br-20{border-radius:20px;}
.reportBtn {background: #bababa;border-color: #bababa;font-size: 14px;}
.siteBlueBtn:hover {background: #4f85e8;}
.tag {border-radius: 5px;padding: 2px 8px;color: #fff;font-weight: 500;font-size: 14px;}
.tag1{background: #56cbcd;}
.tag2{background: #6ed099;}
.userImg{height: 60px;}
.dataRow {background: #ffffff;border-radius: 5px;}
.userImg{border-radius: 100%;}
.username {font-size: 20px;font-weight: 500;color: #6c99ed;}
.dataWrap audio {background: #fff;border-radius: 20px;}
.dataTabs .nav-pills .nav-link {border-radius: 0;}
.dataTabs .nav-item {z-index: 2;}
.dataTabs #pills-tab {position: relative;}
.dataTabs #pills-tab:after {content: "";background: #c8d7f2;position: absolute;width: 100%;height: 2px;top: 50%;}
.dataTabs #pills-tab:before {content: "";background: #edf4fd;position: absolute;width: 16%;height: 2px;top: 50%;z-index: 1;}
.dataTabs .nav-pills .nav-link.active, .dataTabs .nav-pills .show > .nav-link {background-color: #6c99ed;}
.dataTabs .nav-link {color: #424242;background: #fff;}
.selectNumber {background: #fff;border: 2px solid #6c99ed;padding: 0 10px;border-radius: 10px;color: #6c99ed;}
.card .zoneBox {color: #000000;font-weight: 500;text-decoration: none;border-bottom: 1px solid #c2c2c2;display: flex;align-items: center;position: relative;}
.card .zoneBox:after {content: "";background: red;position: absolute;bottom: -1px;left: 0;width: 0;height: 1px;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;-webkit-transition: 0.5s ease all;}
.card .zoneBox:hover:after{width: 100%;}
.zoneWrap .card-header{border-radius: 5px !important;box-shadow: 0 0 20px rgba(48,92,173,.2);}
.card1 .card-header{background: #4f4f4e;}
.card2 .card-header{background: #b0117a;}
.card3 .card-header{background: #b5c004;}
.card4 .card-header{background: #0659b2;}

.card1 .zoneIcon{background: #4f4f4e;}
.card2 .zoneIcon{background: #b0117a;}
.card3 .zoneIcon{background: #b5c004;}
.card4 .zoneIcon{background: #0659b2;}

.card1 .zoneBox:after{background: #4f4f4e;}
.card2 .zoneBox:after{background: #b0117a;}
.card3 .zoneBox:after{background: #b5c004;}
.card4 .zoneBox:after{background: #0659b2;}
.zoneWrap .card .card-header h4 {font-size: 20px;font-weight: 600;}
.zoneBox{font-size: 15px;}

.zoneBoxUl {display: flex;flex-direction: column;list-style: none;margin: 0;padding: 0;}
.zoneBoxLi {display: flex;}
.zoneIcon {height: 33px;width: 33px;display: flex;align-items: center;justify-content: center;border-radius: 100%;}

.tagIcon .dropdown-toggle:after {background: url(../images/arrow.png) no-repeat;border: none;height: 10px;width: 15px;margin: 0 0 0 5px;position: relative;top: 5px;}
.recControls {max-width: 735px;}
.attemptText strong {font-weight: 500;}
.zoneWrap .card-body {border-radius: 5px;box-shadow: 0 0 20px rgba(48,92,173,.2);}

.searchFilter {display: flex;max-width: 250px;}
.statusFilter .form-control {width: 200px !important;}
.orderFilter .form-control {width: 200px !important;}
.searchFilter{display: flex;max-width: 250px;width: 100%;flex-wrap: wrap;}
.searchFilter button {border-top-left-radius: 0;border-bottom-left-radius: 0;}
.searchFilter .form-control {border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;width: calc(100% - 41px);}
.zoneFilters label {text-align: center;font-size: 14px;color: #9a9a9a;margin: 0 0 5px 0;}
.zoneFilters select{appearance: auto !important;-o-appearance: auto;-moz-appearance: auto;-webkit-appearance: auto;}
.zoneFilters .form-control{color: #9a9a9a !important;font-size: 14px;}
.zoneTabs .nav.nav-pills {border: 2px solid #345fb0;background: #345fb0;padding: 0;border-radius: 30px;}
.zoneTabs .nav-link {color: #ffffff;font-weight: 500;border-radius:30px;}
.zoneTabs .nav-pills .nav-link.active{color: #345fb0;background-color: #b9ed2e;}
.zoneTabs th {color: #345fb0;font-weight: 500;border-color: inherit !important;}
.zoneTabs .table {font-size: 14px;border: 1px solid #ddd;}
.zoneTabs .table td, .zoneTabs .table th{padding: 18px 10px;}
.zoneTabs .fa-tag {font-size: 26px;color: #345fb0;margin: 0 0 0 10px;}
.zoneTabs .table > thead {background: #345fb0;}
.zoneTabs .table > thead th {color: #fff;}
.pageTitleBlue{background: #345fb0 !important;color: #fff;padding: 15px 24px;font-size: 26px;font-weight: 400;}
.zoneTabs .page-link {color: #fff;background-color: #345fb0;border: 1px solid #7a97cc;}

.perfect-scrollbar-on .main-panel {width: calc(100% - 18%);background: transparent !important;}
.wrapper {display: flex;justify-content: space-between;}

.pteTutorial .nav-pills:not(.flex-column) .nav-item:first-child .nav-link, .pteTutorial .nav-pills:not(.flex-column) .nav-item:last-child .nav-link {border-radius: 0 !important;}
.pteTutorial .nav-pills .nav-item .nav-link {border: none;text-transform: capitalize;font-weight: normal;border-bottom: 2px solid transparent !important;padding: 10px 15px;}
.pteTutorial .totorialTabs .nav-pills .nav-link.active, .pteTutorial .totorialTabs .nav-pills .show > .nav-link {color: #2c5ec7 !important;background-color: transparent !important;border-bottom:2px solid #2c5ec7 !important;}

.leftSidebar .dropdown .dropdown-menu {opacity: 1;visibility: visible;display: none;box-shadow: none;padding-bottom: 0;padding-top: 0;}
.leftSidebar .dropdown .dropdown-menu.show{display: block;}
.leftSidebar .dropdown-menu .dropdown-item{color: #ffffff;background: transparent;}
.siteContent, .content {padding: 1%;}
.leftSidebarClosed .avatarImg {height: 70px;width: 70px;}
.leftSidebarClosed .userAvatar {height: 61px;width: 61px;}
.leftSidebarClosed .crownImg {height: 25px;}
.cardBox small, .cardBox .small, .cardBlock small, .cardBlock .small{font-weight: 600;}


.leftSidebarClosed .dropdown-toggle:after{position: absolute;right: 30px;top: 17px;}
.leftSidebarClosed .dropdown-toggle:hover:after{color:#b9ed2e;}
.leftSidebarClosed .menuItem .dropdown-toggle:focus:after{color:#b9ed2e;}

.loaderWrap {position: fixed;left: 0;top: 0;height: 100%;width: 100%;background: rgba(255,255,255,.8);z-index: 123;}
.la-loader {position: absolute;height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;}
.la-loader img {max-height: 350px;}
.blur {filter: blur(8px);}
.modal-body input[type="date"] {box-sizing: border-box;outline: 0;padding: .75rem;position: relative;width: 100%;}
input[type="date"]::-webkit-calendar-picker-indicator {background: transparent;bottom: 0;color: transparent;cursor: pointer;height: auto;left: 0;position: absolute;right: 0;top: 0;width: auto;}
.modal-open {padding-right: 0 !important;}
.modal-open .input-group-text {width: auto !important;}
.dashboardPage .modal-header {text-align: left; display: flex;}
.border-bottom {border-bottom: 1px solid #FFF;}

.class-list a {white-space: nowrap;width: calc(100% - 70px);overflow: hidden;text-overflow: ellipsis;}
.mySubs .class-list a {width: calc(100% - 100px);}
.upcomingClassBox ul li.show-more {text-align: center; display: block;}
.sidebar-fixed {position: fixed;top: 0;left: 0;width: 17%;overflow: auto;transition: 0.5s ease all;-o-transition: 0.5s ease all;-moz-transition: 0.5s ease all;
-webkit-transition: 0.5s ease all;height: 100%;overflow-x: hidden;background: #345fb0;z-index: 12;padding-bottom: 50px;}


.sidebar-fixed::-webkit-scrollbar, .student-portal .rightWrap::-webkit-scrollbar, .student-portal.student-dashboard .dashboardPage .siteContent::-webkit-scrollbar, .student-portal.student-dashboard .dashboardPage .content::-webkit-scrollbar{
	width: 0;
}

.passive-link {
    text-decoration: underline;
}
.passive-link:hover{opacity: 0.6; transition: 0.4s ease all;}
.quesPrac {background: #fff;border-radius: 20px;display: flex;flex-direction: column;justify-content: center;padding: 10px 15px;text-align: center;font-size: 22px;
color: #59c1fd;font-weight: 600;box-shadow: 0 10px 10px rgb(0 0 0 / 10%);max-width: 163px;}
.show-more a{transition: 0.3s ease all;}
.withoutBar .leftSidebar{display:none;}
.withoutBar .content, .withoutBar .siteContent{width:100% !important;}
/*html.student-dashboard {overflow: hidden;}*/
li.menuItem.dropdown .dropdown-menu .menuCurrent {
    background: transparent;
    color: #b9ed41;
}
.daysPracticed{border-left: 2px solid #ffffff;border-right: 2px solid #ffffff;}
.listTableWrap {overflow: auto !important;}
.change-password {
    border-radius: 2px;
    font-size: 14px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: #e7aa3a !important;
    color: #FFF;
    transition: 0.3s ease all;
}
.change-password:hover {
    color: #e7aa3a !important;
    background-color: #fff4e0 !important;
    border: 1px solid #e7aa3a !important;
    transition: 0.3s ease all;
}
.underline {text-decoration: underline !important;transition: 0.3s ease all;}
.underline:hover {text-decoration: none !important;opacity: 0.8;transition: 0.3s ease all;}
.selection-area {user-select: text;}
.scoring-mode .btn {
    border: none;
    text-transform: capitalize;
    font-weight: normal;
    border-bottom: 2px solid transparent !important;
    padding: 10px 15px;
    background: transparent !important;
    color: #333;
    border-radius: 0 !important;
    font-size: 18px;
}
.highlightedOverlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.8);
    left: 0;
    top: 0;
    z-index: 12;
    transition: 0.3s ease all;
}
.highlighted.selection-area {
    margin-top: -20%;
}
.highlighted.selection-area .text {
    position: relative;
    z-index: 123;
    background: #ffffff;
    color: #11429e;
    text-align: center;
    padding: 20px 20px;
}
.questionsArea h5 {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    line-height: normal;
    margin: 0;
}
.selection-area h5::-moz-selection { /* Code for Firefox */
  color: #ffffff;
  background: #11429e;
}

.selection-area h5::selection {
  color: #ffffff;
  background: #11429e;
}
.op-buttons {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    position: relative;
    z-index: 123;
}
.selection-area p {
    position: relative;
    z-index: 123;
    text-align: center;
    color: #fff;
    font-size: 30px;
    pointer-events: none;
    text-decoration: underline;
}

.op-buttons .fa {
    margin: 20px 10px 10px;
    cursor: pointer;
    border: 1px solid #fff;
    font-size: 28px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}
.op-buttons .fa.fa-check{color: #94c246;
    border-color: #94c246;}
.op-buttons .fa.fa-close {
    border-color: red;
    color: red;
}
.scoring-mode .btn:focus, .scoring-mode .btn:active , .scoring-mode .btn:active:focus {
    background: transparent !important;
}
.scoring-mode .btn:hover, .scoring-mode .btn.btnActive {
    border-bottom-color: #11429e !important;
    color: #11429e !important;
}
.text i {
    margin-top: -40px;
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: 16px;
    padding: 5px 7px;
    margin-left: 49.5%;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s ease all;
}
.text i:hover {
    font-size: 16px;
    color: #e77474;
    border: 1px solid #e77474;
    transition: 0.3s ease all;
}
.text h5 {
    font-size: 20px !important;
    line-height: 1.5 !important;
}
.three-border {
    border-top: 2px solid #94c23c;
    border-left: 2px solid #94c23c;
    border-right: 2px solid #94c23c;
}
.border-three {
    border-bottom: 2px solid #94c23c;
    border-left: 2px solid #94c23c;
    border-right: 2px solid #94c23c;
}
.border-label {
    position: absolute;
    right: 0px;
    font-size: 16px;
    background-color: #94c23c;
    color: #fff;
    padding: 1px 5px;
}
.scoreWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 80%;
    margin: 0 auto;
}
.scoreWrap h4 {
    height: 120px;
    width: 120px;
    border-radius: 75px;
    border: 2px solid #345FB0;
    color: #345FB0;
    padding: 5px 5px;
    display: flex;
    font-size: 21px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #345FB0;
    box-shadow: 0 0 0 5px #fff inset;
    font-weight: 600;
}
.scoreWrap h4 img {
    margin: 0 10px 0 0;
}
.scoreWrap span {
    color: #94c23c;
}
.scoreWrap h3 {
    color: #345fb0;
    font-size: 12px !important;
}
.avg-pace {
    font-weight: 600;
    background: #345fb0;
    margin: 50px auto 0;
    max-width: 300px;
    padding: 0 20px 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    border-radius: 12px;
}
.a-score {
    background: #94c23c;
    margin: 0 auto 20px;
    padding: 10px 20px;
    font-size: 40px;
    line-height: 1;
    border-radius: 0 0 8px 8px;
    font-weight: bold;
}
.total-score-box h4 {
    border-radius: 0 !important;
}
.total-score-box h4 span { color: #FFF !important; }
.word-feedback { color: #654b4b; font-size: 13px; }
.color-warning { color: #fb8606 }
.color-success { color: #0bd31d }
.color-danger { color: #f50909 }
.tooltip-word { padding: 2px; }
.tooltip-word:hover {
    background-color: #345fb0;
    border-radius: 5px;
    cursor: pointer;
}

.popup-content-heading {
    border-bottom: 1px solid #e7dcdc;
    padding: 10px 0;
}
.popup-content-data .row {
    border-bottom: 1px solid #e7dcdc;
    padding: 10px 0;
}
.word-details table {font-size: 12px !important;}
.word-details-modal .modal-footer button {
    border-radius: 5px !important;
    padding: 5px 5px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}
.word-details .normal-word { font-size: 13px; }
.word-details .word-pronunciation {
    border: 1px solid #345FB0;
    border-radius: 20px;
    width: 24px;
    height: 24px;
    padding-top: 2px;
    background-color: #345FB0;
    color: #FFF;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
}
.word-details .word-pronunciation:hover {
    padding-top: 1px;
    font-size: 14px;
    box-shadow: 4px 0px 4px #AAA;
    border: 1px solid #7a91bb;
    background-color: #7a91bb;
    width: 25px;
    height: 25px;
}
.practice-material-categories .alert {
    box-shadow: 5px 10px 8px grey;
    font-weight: 400 !important;
    margin-bottom: 25px;
}
.material-category-title {font-weight: 500 !important; font-size: 36px !important;}
.material-category-title.speaking {color: #65d1d4;}
.material-category-title.writing {color: #fccf71;}
.material-category-title.reading {color: #66c4de;}
.material-category-title.listening {color: #7ed6a5;}
.material-header {padding: 10px 42px 0 !important; }
.practice-material-categories .card-body .col-3 > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ai-tag { width: 24px; margin-left: auto; }
.question-strategy-video {
    text-align: center;
}
.question-strategy-video button {
    background: #346795 !important;
    border-radius: 3px !important;
    padding: 5px 15px !important;
}
.question-strategy-video button:hover {
    border: 1px solid #346795 !important;
}

.payment-methods label {
    font-size: 16px !important;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease all;
    text-align: center;
    justify-content: center;
}
.duration-box label {height: 200px; padding-top: 50px; background-color: #bfc3b4; font-weight: bold !important; color: #FFF !important;}
.payment-methods label:hover {
    background-color: #b9ed2e;
    transition: 1s ease all;
}
.payment-methods label.active {
    color: #345fb0 !important;
    font-size: 24px !important;
    background-color: #b9ed2e;
    transition: 0.5s ease all;
}
.payment-methods label.active:hover {
    opacity: 1;
}
.payment-methods label input[type="radio"] {
    position: fixed;
    opacity: 0;
}
.siteContent .prediction-page-title {font-weight: 300; font-size: 20px; text-align: center; color: #5f9ea0;}

.examDate  p small {
    line-height: normal;
}
.sidebar-fixed .crossBtn {
    display: none;
}
.sidebar-wrapper {
    width: 100%;
    padding-bottom: 10px;
}
.leftSidebarClosed .sidebar-wrapper {
    width: calc(100% - 23%);
    transition: 0.5s ease all;
}
.leftSidebarClosed .closeSidebar img {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.select-voices {
    max-width: 500px;
    margin: 25px auto;
    text-align: center;
}
.select-voices select {
    text-transform: capitalize;
}
.script-options h3 {
    margin-bottom: 0;
}
.select-language {
    font-size: 14px;
}
.practice-shadowing-modal .text-translation h4 {
    margin: 0;
    font-size: 24px;
    text-transform: uppercase;
    color: #fef993;
    font-weight: 700;
    margin-left: 15px;
    text-align: left;
}
.practice-shadowing-modal .listen-audio h4 {
/*    margin: 0;*/
    font-size: 24px;
    text-transform: uppercase;
    color: #fef993;
    font-weight: 700;
    margin-left: 15px;
}
/*.text-translation .ques-text {
    min-height: 100px;
}*/
.paragraph-audio h4 {
    margin: 0;
    font-size: 20px;
}
.practice-shadowing-modal .modal-body .text-to-translate {
    background: #edeffc;
    border-radius: 20px;
    padding: 20px 25px;
    color: #544646;
    font-size: 18px;
}
.practice-shadowing-modal .listen-audio .ques-text {
    min-height: 210px;
    font-size: 18px;
    padding: 20px;
    background: #94c23c3b;
    border-radius: 25px;
    background-color: #fff;
}
/*.text-translation .definition-text,*/
.listen-audio .definition-text {
    min-height: 210px;
    font-size: 20px;
    border: 3px solid #94c23c;
    padding: 10px 7px;
    background: #94c23c3b;
    border-radius: 5px;
    color: #3c5708;
}
/*.text-translation .definition-text ol li,*/
.listen-audio .definition-text ol li {
    padding-bottom: 5px;
    border-bottom: 1px dotted green;
    margin-top: 10px;
}
.audio_script_text .word:hover {
    cursor: pointer;
    background-color: blue;
    color: white;
}
.word-definition {
    white-space: pre-line;
}
.practice-shadowing-modal.text-translate-modal .modal-content {
    border-radius: 20px !important;
}
.practice-shadowing-modal.text-translate-modal .modal-header {
    background-color: #23b67f;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.practice-shadowing-modal.listen-audio-modal .modal-content .listen-audio-icon {
    position: absolute;
    right: 100px;
    top: -50px;
    width: 120px;
}
.practice-shadowing-modal.listen-audio-modal .modal-content {
    background-color: #1977e9;
    border-radius: 20px !important;
}
.practice-shadowing-modal.text-translate-modal .basic-single {
    text-align: left;
}
.practice-shadowing-modal.text-translate-modal .select__placeholder,
.practice-shadowing-modal.text-translate-modal .select__single-value {
    color: #23b67f;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 10px;
}

.practice-shadowing-modal.listen-audio-modal .select__control,
.practice-shadowing-modal.text-translate-modal .select__control {
    border-radius: 20px;
}
.practice-shadowing-modal .select__placeholder,
.practice-shadowing-modal .select__single-value {
    color: #1a7bf7;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 10px;
}
.modal-backdrop.shadow-backdrop {
    background-color: #174791;
    opacity: 0.94 !important;
}
.practice-shadowing-modal .close-button {
    background-color: #ff6160 !important;
    font-weight: 500;
}
.practice-shadowing-modal .close-button:hover {
    background-color: #d33736 !important;
    color: #FFF !important;
    border-color: transparent !important;
    font-weight: 500 !important;
}

/* Word Definition */
.practice-shadowing-modal.word-definition-modal .modal-content {
    border-radius: 20px !important;
}
.practice-shadowing-modal.word-definition-modal .modal-header {
    background-color: #23b67f;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.practice-shadowing-modal .word-definition-heading h4 {
    margin: 0;
    font-size: 27px;
    text-transform: uppercase;
    color: #fef993;
    font-weight: 700;
    margin-left: 15px;
    text-align: left;
}
.practice-shadowing-modal .word-definition-heading img {
    width: 80px;
}
.practice-shadowing-modal .modal-body .definition-text {
    background: #edeffc;
    border-radius: 20px;
    padding: 20px 25px;
    color: #544646;
    font-size: 18px;
}
.practice-shadowing-modal .word-definition-heading h4 i {
    margin-left: 20px;
    font-size: 27px;
    color: #08560e;
    cursor: pointer;
}
.practice-shadowing-modal .word-definition-heading h4 i:hover {
    color: #FFF;
    transition: 0.3s ease all;
}
.urdu-alignment{
    text-align: right;
    direction: rtl;
}
.stop-btn .select-voice .label-class {
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    background-color: #345eb0;
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.stop-btn .select-voice .label-class:hover {
    border: 1px solid #345eb0 !important;
    color: #345eb0 !important;
}
.stop-btn .select-speed .label-class {
    font-weight: 500;
    font-size: 15px;
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.react-tel-input.a-form-control input {
    height: auto !important;
    width: 100% !important;
    background: #f3f8fb !important;
    padding-left: 60px !important;
}
.react-tel-input.a-form-control .flag-dropdown .selected-flag {
    width: 50px;
    padding: 0 0 0 15px;
}
.react-tel-input.popup-class input {
    width: 100%;
    height: 45px;
}
.phone-dropdown .dropdown-menu {
    max-height: 20rem;
    overflow-y: hidden;
    border-radius: 0;
    max-width: 350px;
    width: 350px;
    margin: 4px 0 0 -6px;
}
.popup-class .phone-dropdown .dropdown-menu {
    margin: -12px 0 0 8px;
}
.phone-dropdown .dropdown-toggle {
    border-radius: 3px;
    margin-left: 0;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 70px;
}
.phone-dropdown .dropdown-toggle:after {
    color: #9d9e9d;
}
.phone-dropdown .dropdown-menu input {
    margin: 10px 10px 12px;
    width: 100%;
    padding: 10px 15px;
    position: absolute;
    top: -10px;
    left: -10px;
    border-radius: 0;
}
.phone-dropdown .items-container {
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    margin-top: 44px;
}
.phone-dropdown .dropdown-menu .dropdown-item {
    border-bottom: 1px dotted gainsboro;
    padding: 5px 15px;
}
.phone-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #94c33c !important;
    transition: 0.2s ease all;
}
.phone-dropdown .dropdown-menu .dropdown-item .country-code {
    display: inline-block;
    width: 55px;
}
.popup-class .phone-field {
    width: 100%;
}
.register-page .phone-field {
    padding: 20px 25px !important;
}
.register-page .dropdown-toggle {
    padding: 21px 5px !important;
}
.listen-audio .play-pause-button {
    padding: 11px 15px;
    background-color: white;
    border-radius: 25px;
    font-size: 15px;
    cursor: pointer;
    margin-left: 25px;
    color: #1977e9;
    width: 100%;
    text-align: center;
}
.listen-audio .play-pause-button:before {
    margin-right: 5px;
}
.listen-audio .play-pause-button:hover {
    background: #c2dbf6;
    transition: 0.2s ease all;
}
.mock-test-page .adminPlansOuter {
    width: 100% !important;
}
.videosDataOuter {
    background: #4370c3;
    border-radius: 20px;
    padding: 20px 25px;
}
.strategyIntrOuter h2 {
    font-size: 38px;
    font-weight: normal;
    color: #fff;
    margin-bottom: 20px;
}
.introVideOuter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.introVideOuter > div {
    width: 50%;
}
.introVideOuter > div.introImgOuter {
    padding-right: 50px;
}
.introVideOuter > div.introImgOuter > div {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.4);
    border: 3px solid #fff;
    /* The below style prop is added by anmol */
    transition: box-shadow 0.3s ease;
    cursor: pointer;
}
.introVideOuter > div.introImgOuter > div:hover {
    box-shadow: none;
}
.introImgOuter img {
    height: 250px;
    object-fit: cover;
    width: 100%;
    object-position: top center;
}
.introVideOuter > div.introImgOuter .introTitle {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    text-align: center;
    padding: 10px 10px;
    font-weight: bold;
    font-size: 25px;
}
.introVideOuter > div.introCntnt h2 {
    font-weight: 600;
    font-size: 60px;
}
.introVideOuter > div.introCntnt h3 {
    background: #fc5d71;
    color: #ffff;
    font-weight: normal !important;
    font-size: 27px !important;
    display: inline-block;
    vertical-align: top;
    width: auto;
    border-radius: 12px;
    padding: 5px 25px;
}
.videoZoneOuter {
    padding: 25px 0;
    margin-bottom: 25px;
    position: relative;
}
.videoZoneOuter:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 97%;
    background: #fff;
    height: 2px;
    content: "";
    margin: auto;
    right: 0;
}
.videoZoneOuter h2 span {
    padding: 7px 30px;
    display: inline-block;
    vertical-align: top;
    border-radius: 20px;
    color: #fff;
    font-size: 25px;
    font-weight: normal;
}
.videoZoneOuter.speakingVideos {
    margin-top: 22px;
}
.videoZoneOuter h2 {
    margin-bottom: 15px;
}
.speakingVideos.videoZoneOuter h2 span {
    background: #8e8e8e;
}
.writingVideos.videoZoneOuter h2 span {
    background: #e83dae;
}
.readingVideos.videoZoneOuter h2 span {
    background: #b7c205;
}
.listeningVideos.videoZoneOuter h2 span {
    background: #004389;
}
.videoChapter {
    width: 25%;
}
.videoChapterInr {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.4);
    border: 3px solid #fff;
    margin: auto auto 25px;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
}
.videoChapterInr:hover {
    box-shadow: none;
}
.videoChapter img {
    height: 200px;
    object-fit: cover;
    width: 100%;
    object-position: top center;
}
.videoChapter .videoChapterTitle {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    text-align: center;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
}
.zoneVideosOuter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.videoZoneOuter.listeningVideos:after {
    display: none;
}
.para-form {
    white-space: pre-line;
    font-size: 17px;
}

.grammar-error {
    border-bottom: 1px dotted blue;
    color: blue;
}
.spelling-error {
    border-bottom: 1px dotted red;
    color: red;
}
.punctuation-error {
    border-bottom: 1px dotted orange;
    color: orange;
}
.new-response {
    font-size: 14px;
    line-height: 0;
}
.not-round {
    border-radius: 4px !important;
    background-color: #345FB0 !important;
}
.essay-data-buttons .not-round:hover {
    background-color: #345FB0 !important;
    border: 1px solid #345FB0 !important;
    color: white !important;
    cursor: initial;
}
.tip .red {
    background-color: #a94044 !important;
}
.tip .yellow {
    background-color: #ac741b !important;
}
.tip .blue {
    background-color: #345fb0 !important;
}
.error-message {
    border-radius: 3px;
    padding-top: .9rem;
    padding-bottom: .9rem;
    position: relative;
    border: 1px solid transparent;
    font-weight: 500;
    padding-left: 1rem;
    padding-right: 1rem;
}
.pre-line {
    white-space: pre-line;
}
.essay {
    border-bottom: 1px dashed;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
}

/* increase cooltipz fontsize */
.essay:after {
    font-size: 18px !important;
    z-index: 13 !important;
}
.essay.bl-bg:after {
    background: #2d3fa9 !important;
}
.essay.rd-bg:after {
    background: #a94044 !important;
}
.essay.or-bg:after {
    background: #ac741b !important;
}
.blanks.explanation-icon {
    font-size: 20px;
    color: #e54848;
    margin-left: 1px;
    border: 2px solid #e54848;
    border-radius: 15px;
    padding: 2px 7px 2px 6px;
    text-decoration: none;
    transition: all 0.2s ease-in-out 0s;
}
.blanks.explanation-icon:hover {
    background-color: #e54848;
    color: #FFF;
}

/* Media Queries */
@media (min-width: 992px){
	.student-portal.student-dashboard .siteContent, .student-portal.student-dashboard .content{max-height: calc(100vh - 1.5rem);overflow-y: auto;}
	.leftSidebarClosed .leftSidebar, .leftSidebarClosed .sidebar-fixed{width: 6%;overflow-x: hidden;}
	.leftSidebarClosed.dashboardPage .siteContent, .leftSidebarClosed.dashboardPage .content {width: calc(100% - 23%);}
	.leftSidebarClosed .siteContent, .leftSidebarClosed .content {width: calc(100% - 6%);}
	.leftSidebarClosed .siteContent.without-rightBar, .leftSidebarClosed .content.without-rightBar {width: 100%;}
	body:not(.leftSidebarClosed) .siteContent, body:not(.leftSidebarClosed) .content, body:not(.leftSidebarClosed) .content{width: calc(100% - 17%);}
	body:not(.leftSidebarClosed).dashboardPage .siteContent, body:not(.leftSidebarClosed).dashboardPage .content{width: calc(100% - 34%);}
	.leftSidebarClosed .rightSidebar{width: 17%;}
	.leftSidebarClosed .rightWrap {width: 17%;}
	.openSidebar {position: absolute;}
	.leftSidebar, .rightSidebar{min-height: 100vh;}
	.openSidebar {display: none;}
	.leftSidebarClosed .menuText {display: none;}
	.leftSidebarClosed .menuItem a:hover, .leftSidebarClosed .menuCurrent a{background: transparent;}
	.leftSidebarClosed .menuItem a{width: 100%;justify-content: center;white-space: normal;}
	.leftSidebarClosed .menuItem .dropdown-menu{padding-left: 5%;padding-right: 5%;}
	.attemptText {position: absolute;left: 0;}
}

.sidebar-wrapper .openSidebarMobile,
.sidebar-fixed .crossBtn {
    display: none;
}
@media (max-width: 1550px){
	.statsWrap:before{left: -4%;}
	.totalMocks{font-size: 20px;}
	.totalMocks b {font-size: 28px;}
	.desiredValue, .daysValue, .practicedValue {font-size: 22px;}
	.goalsColumn a{font-size: 16px;padding: 10px 13px;}
	.cardBlock h4 {font-size: 1.2rem;}
	.menuItem a {font-size: 12px;}
	/*.anotherPlan{font-size: 16px;}*/
	.menuItem a.dropdown-item {font-size: 12px;}
	.statBox{padding: 12px;}
	.desiredScore {padding: 0 2%;border-left: 1px solid;border-right: 1px solid;}
	.detailCard{padding-left: 20px;}
	.openSidebar {left: 10px;}
	.scoreValue {font-size: 76px;}
}

@media (max-width: 1380px) {
.statBox {font-size: 12px;}
.totalMocks {font-size: 18px;}
.totalMocks b {font-size: 24px;}
.menuItem a {font-size: 10px;}
.menuIcon6 {align-self: flex-start;}
.menuIcon {height: 18px;width: 18px;}
.ai-tag {
    width: 20px;
    height: 18px;
}
.practice-material-categories .alert {
    padding: 6px 14px;
    font-size: 12px;
}
.material-category-title {
    font-size: 28px !important;
    margin-bottom: 10px !important;
}
.practice-material-categories .card-body .col-3 > div {
    margin-bottom: 25px;
}
.videoChapter {
    width: 33%;
}
.videoZoneOuter h2 {
    margin-bottom: 20px;
}
.introVideOuter > div.introImgOuter {
    padding-right: 20px;
}

}
@media (max-width: 1199px) {
.statsWrap:before {display: none;}
.desiredValue, .daysValue, .practicedValue {font-size: 18px;}
.scoreImg {width: 100%;}
.leftSidebar {
    width: 22%;
}
body:not(.leftSidebarClosed) .siteContent, body:not(.leftSidebarClosed) .content, body:not(.leftSidebarClosed) .content {
    width: calc(100% - 22%);
}
.sidebar-fixed {
    width: 230px;
}
.avatarImg {
    height: 94px;
    width: 94px;
}
.userAvatar {
    height: 84px;
    width: 84px;
}
.videoChapter {
    width: 50%;
}
.videoChapter img {
    height: 150px;
}

}

@media (max-width: 991px) {
.siteWrap {flex-direction: column;}
.leftSidebar {width: 230px;position: fixed;z-index: 12;height: 100vh;left: -230px;}
.sidebar-fixed {
    left: -230px;
}
.siteContent{width: 100%;}
.rightSidebar{width: 100%;background-position: top center;}
/*.leftSidebarClosed .leftSidebar{display: none;}*/
.desiredScore {
    max-width: 300px;
    margin: 0 20px;
    width: 100%;
}
.examDate {max-width: 300px;width: 100%;margin: 0 auto;}
.daysPracticed {padding: 20px 0 20px 0;margin-top: 20px;}
.cardBlock h4 {font-size: 1rem;}
/*.statBox {flex-direction: column;}
.openSidebar {float: left;width: 100%;}*/
.nav-pills .nav-link {font-size: 13px;padding: 5px 10px;}
.leftSidebarOpen .leftSidebar{display: block;}
.scoreValue {font-size: 60px;}
.dataTabs #pills-tab:after {display: none;}
body { font-size: 14px; }
.dashboardPage .cardBlock .col-md-12 {
    width: 50%;
}
.totorialTabs li.nav-item {
    width: auto;
}
.desiredScore p.align-self-end {
    align-self: center !important;
}
.daysPracticed {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.totalMocks {
    font-size: 15px;
    min-width: 130px;
}
.sidebar-wrapper .openSidebarMobile img {
    width: 30px;
    height: auto;
    cursor: pointer;
}
.sidebar-wrapper .closeSidebar {
    display: none;
}
.sidebar-wrapper .openSidebarMobile {
    display: block;
}
.sidebar-fixed .crossBtn {
    right: 14px;
    top: 22px;
    position: absolute;
    display: flex;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background: #b9ed2e;
    text-align: center;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.sidebar-fixed .crossBtn img {
    height: auto;
    width: 13px;
}
.openSidebar .leftSidebar,
.openSidebar .sidebar-fixed {
    left: 0;
}
.introVideOuter > div.introCntnt h3 {
    font-size: 20px !important;
}

}
@media (max-width: 767px) {
.material-category-title {
    font-size: 28px !important;
    margin-bottom: 7px !important;
}

}
.pte-core__title {
    padding: 4px 26px;
    background-color: rgba(255,255,255,.4);
    border-radius: 10px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}

@media (max-width: 1242px) {
    .pte-core__title {
        font-size: 0.9rem;
    }
}

@media (max-width: 1160px) {
    .pte-core__title {
        font-size: 0.6rem;
    }
}
.modal-dialog.paymentFailedPop {
    max-width: 420px;
    padding-top: 10px;
}
.paymentFailedPop .modal-body {
    background-image: url(../images/1-06.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
}
.paymentFailedPop .modal-body .popTitle {
    font-weight: 700;
    text-transform: none;
    font-size: 20px;
    margin-bottom: 20px;
}
.btn.btn-primary.btnOutline {
    background: transparent;
    color: #94c23c;
    border: 1px solid #94c23c;
}
.btn.btn-primary.btnOutline:hover {
    background: #94c23c !important;
    color: #fff !important;
    border: 1px solid #94c23c;
}
.errorImg {
    text-align: center;
}
.errorImg img {
    width: 300px;
    margin-bottom: 20px;
}
.paymentFailedPop .modal-body {
    padding: 34px !important;
}
.paymentFailedPop .row.popBtns {
    margin-top: 50px;
}
.switchBtns .btn-group {
    background: #fff;
    border-radius: 40px !important;
    padding-right: 35px;
}
.switchBtns button.btn {
    margin: 0 -35px 0 0;
    background: #fff;
    color: #1f4386;
    font-weight: 500;
    border-radius: 40px !important;
    padding: 12px 50px;
    position: relative;
    border-color: #1f43863b;
}
.switchBtns button.btn:focus, .switchBtns button.btn.active {
    background: #1f4386 !important;
    color: #fff !important;
    border-color: #1f4386 !important;
    z-index: 99;
}
.switchBtns button.btn:hover {
    background: #bbd4f5 !important;
    border-color: #bbd4f5 !important;
    color: #1f4386 !important;
    z-index: 0;
}
.switchBtns button.btn.active:hover {
    z-index: 9;
}
.text-center.switchBtns {
    margin: 40px 0 25px;
}
.switchBtns button.btn:nth-child(2) {
    border-color: #1f43863b;
    position: relative;
    z-index: 1;
}
body.leftSidebarClosed.package-payment-page {
    background: #fff !important;
}
.payCardsOuter.card.card-body {
    box-shadow: none;
    padding: 20px 0 0;
}
.payCardInr {
    padding: 20px 10px;
    background-image: url("../images/silver-gradient.svg");
    background-size: cover;
    background-position: top center;
/*    border-radius: 20px 50px 50px;*/
    border-radius: 20px 20px 20px;;
}
.payCardInr.gold {
    background-image: url("../images/golden-gradient.png");
}
span.planDuration {
    display: inline-block;
    vertical-align: top;
    margin-left: -10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 5px 20px 5px 10px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}
.skyblue span.planDuration,
.payCardInr.skyblue .btn-block.planBtn {
    background-color: #34b6b8;
}

.button-payment__razorpay {
    position: absolute;
    bottom: 12%;
    width: 85% !important;
    right: 8%;
}
.darkblue span.planDuration,
.payCardInr.darkblue .btn-block.planBtn {
    background-color: #224fa0;
}
.planGreen span.planDuration,
.payCardInr.planGreen .btn-block.planBtn {
    background-color: #94c23c;
}
span.planRange {
    margin: 15px 0 10px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-weight: 700;
    font-size: 22px;
    color: #000;
}
.payCardInr  .btn-block.planBtn {
    padding: 6px 10px;
    font-size: 14px;
    width: 100%;
    margin: 0;
}
.paymentFormOuter li.nav-item .btn-lg {
    border: 1px solid #34b5b8;
    color: #34b5b8;
    margin: 0px 10px;
}
.payCardInr .planDuration .paymentType {
    font-size: 8px;
}
.adminPlan .adPlanHead {
    position: relative;
}
.adminPlan .adPlanHead .discountStrip {
    position: absolute;
    top: 0;
    left: 30px;
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    font-weight: bold;
    letter-spacing: 0px;
    background-image: linear-gradient(0deg, rgba(189,154,58,1) 0%, rgba(248,239,146,1) 50%, rgba(189,154,58,1) 100%);
    background-color: rgba(189,154,58,1);
    color: #333;
    width: auto;
    right: inherit;
    border: 1px solid #d5b767;
    border-top-width: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 3px 4px 6px 2px rgba(0,0,0,0.2);
}
.adminSPlan .adPlanHead .discountStrip {
    background-image: linear-gradient(0deg, rgba(184,196,202,1) 0%, rgba(255,255,255,1) 50%, rgba(184,196,202,1) 100%);
    background-color: rgb(184,196,202);
    border-color: rgb(212 216 219);
}
.adminPlanInr .adPlanBody ul li.vip {
    position: relative;
}
.adPlanBody .vip img {
    height: 26px;
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
}
.adPlanHead .best-seller-tag {
    position: absolute;
    top: 0;
    right: 25px;
    display: inline-block;
    vertical-align: top;
    padding: 4px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: rgb(255,235,68);
    background: linear-gradient(90deg, rgba(255,235,68,1) 0%, rgba(255,245,154,1) 22%, rgba(255,244,151,1) 50%, rgba(255,245,154,1) 80%, rgba(255,235,68,1) 100%);
    color: #000;
}
.adminPlanInr .adPlanHead .checkedPlan {
    position: absolute;
    left: 15px;
    top: 15px;
    background: #fff;
    border-radius: 50%;
    display: none;
}
.adminGPlan .adminPlanInr .adPlanHead .checkedPlan {
    display: block;
}
.adminPlanInr .adPlanHead .checkedPlan {
    position: absolute;
    left: 15px;
    top: 6px;
    background: #fff;
    border-radius: 50%;
    display: none;
}
.adminPlanInr .adPlanHead .checkedPlan img {
    height: 28px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: block;
    vertical-align: top;
}
.shortPlnHead .planTopTag {
    position: absolute;
    top: 0;
    right: 15px;
    background: #fff;
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 500;
}

.main-container {
    height: 15rem
}

.payCardInr-h100 {
    height: 100%
}

.payCardInr-duration {
    font-size: 9px; 
    font-weight: bold
}

.payCardInr-price {
    font-size: 2.5rem !important;
}
@media screen and (max-width: 580px) {
.videoChapter {
    width: 100%;
}
.introVideOuter {
    flex-wrap: wrap;
}
.introVideOuter > div {
    width: 100%;
}
.introVideOuter > div.introCntnt {
    margin-top: 30px;
}
.introVideOuter > div.introCntnt h2 br {
    display: none;
}

}
@media screen and (max-width: 480px) {
    .questionsPracticed {
        padding: 0 !important;
    }
    .quesPrac {
        max-width: 100%;
        padding: 25px 0;
    }
}

.silver-card__price {
    border-radius: 20px 50px 50px !important;
}

.button-resent__link {
    border: 0;
    outline: 0;
    cursor: pointer;
    color: white;
    background-color: rgb(84, 105, 212);
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(84 105 212) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 8px;
    display: inline-block;
    min-height: 28px;
    transition: background-color .24s,box-shadow .24s;
}

.button-resent__link:hover {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(84 105 212) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}

.link-verify__container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px;
}

.link-verify__message {
    margin-bottom: 15px;
}
.hideBtns {
    transition: all 0.3s ease-in-out 0s;
    visibility: hidden;
    z-index: -1;
    position: absolute;
    opacity: 0;
}
.showBtns {
    height: auto;
    width: auto;
    visibility: visible;
    z-index: 9;
    position: absolute;
    transition: all 0.6s ease-in-out 0s;
    opacity: 1;
}