.businessBanner {
    background-image: url(../images/business-banner-bg.png);
    position: relative;
    background-color: #0a35b5;
    background-position: -80px center;
    padding-top: 120px;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: screen;
}
.bBannerCntnt {
	padding-top: 130px;
    padding-bottom: 140px;
}
.bannerAiOuter {
    background-image: url(../images/new-ai-bg.png);
    background-blend-mode: screen;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: #0a35b5;
    background-position: center;
}
.businessBanner:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -5px;
    height: 90px;
    width: 200%;
    background: #bfcdf4;
    transform: rotate(-2.7deg);
    z-index: 9;
}
.businessBannerInr h2 {
    color: #fff76f;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 12px;
}
.subHeading {
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 20px;
}
.businessBannerInr p {
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
}
.businessBannerInr p span {
	color: #fff76f;
}
.btn.whiteBtn {
    border-radius: 10px;
    margin: 20px 0 0;
    background: #fff;
    color: #0a35bb;
    font-weight: 500;
    text-transform: capitalize;
    padding: 13px 45px;
    font-size: 16px;
    cursor: pointer;
    width: 155px;
}
.bannerAiOuter h3 {
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
    font-weight: bold;
    font-size: 70px;
}
.featuresOuter {
    margin-top: -65px;
    z-index: 99;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.featuresOuter .featureBlock {
    background: #fff;
    margin: 0 8px;
    padding: 22px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    text-align: center;
    width: 160px;
    box-shadow: 0px 0px 28px 0px rgba(0,0,0,0.2);
}
.featureIcon img {
    height: 50px;
    width: auto;
}
.featureBlock p {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin: 20px 0 0;
    min-height: 40px;
}
.featuresInfo {
    position: relative;
}
.featuresInfo:before {
    content: "";
    position: absolute;
    bottom: 272px;
    left: -5px;
    height: 80px;
    width: 200%;
    background: #bfcdf4;
    transform: rotate(-2.7deg);
    z-index: 9;
}
.featuresInfo:after {
    content: "";
    position: absolute;
    bottom: 152px;
    left: -5px;
    height: 120px;
    width: 200%;
    background: #284fce;
    transform: rotate(-2.7deg);
    z-index: 9;
}
.taskFeatures {
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
    position: relative;
}
.taskFeatures h4 {
    color: #284fce;
    font-weight: 500;
    font-size: 18px;
    margin-top: 0;
}
.tfMainCntnt .whiteBtn {
    margin-top: -72px;
    border-radius: 17px;
    font-size: 14px;
    font-weight: 600;
    width: auto;
}
.taskFeatures .taskFeatureBlk {
    flex-direction: row;
    position: absolute;
    left: 0px;
    top: 27%;
    background: #fff;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    width: 169px;
    align-items: center;
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.3);
    z-index: 99;
}
.tfTitle {
    color: #0a3ec4;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}
.tfTitle samp {
    display: block;
    vertical-align: top;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    color: #303030;
    font-weight: 500;
}
.tfIcon {
    padding-right: 10px;
}
.taskFeatures .taskFeatureBlk.tfBlk02, .taskFeatures .taskFeatureBlk.tfBlk04 {
    top: 40%;
}
.taskFeatures .taskFeatureBlk.tfBlk03,
.taskFeatures .taskFeatureBlk.tfBlk04 {
    left: inherit;
    right: -20px;
}
.tfInfo {
    text-align: left;
}
.tfIcon img {
    height: 30px;
}
.taskFeatures .taskFeatureBlk.tfBlk03 .tfTitle {
    color: #333;
    font-size: 17px;
}
.taskFeatures .taskFeatureBlk.tfBlk03 samp {
    display: inline-block;
    vertical-align: top;
}
.taskFeatures .taskFeatureBlk.tfBlk03 samp img {
    width: 84px;
}
.taskFeatures .taskFeatureBlk.tfBlk01, .taskFeatures .taskFeatureBlk.tfBlk02 {
    transform: rotate(-10deg);
}
.taskFeatures .taskFeatureBlk.tfBlk03, .taskFeatures .taskFeatureBlk.tfBlk04 {
    transform: rotate(10deg);
}
.taskFeatures .taskFeatureBlk.tfBlk02 {
    left: 1%;
}
.taskFeatures .taskFeatureBlk.tfBlk04 {
    right: 0%;
    top: 42%;
}
.tfMainCntnt {
    position: relative;
    z-index: 99;
}
.tfScreen img {
    width: 680px;
}
.helpCntnt {
    padding-left: 70px;
}
.helpCntnt h2 {
    color: #284fce;
    font-size: 23px;
    margin-bottom: 20px;
    font-weight: 500;
}
.helpCntnt p {
    line-height: 20px;
    margin-bottom: 40px;
}
.helpFeatures .impo-list {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.helpFeatures  .impo-list li {
    width: 50%;
    margin-bottom: 6px;
}
.helpFeatures  .impo-list .fa.fa-check-circle {
    font-size: 19px;
}
.helpFeatures .impo-list li b {
    font-size: 15px;
    color: #355acf;
    font-weight: 500;
}
.socialIcon  i.fa.fa-facebook {
    float: none;
}
.footerWidget ul {
    padding-left: 0;
}
@media only screen and (max-width: 1260px) {
.tfScreen img {
    width: 700px;
}
.taskFeatures .taskFeatureBlk {
    left: 0px;
    top: 27%;
    z-index: 99;
}
.taskFeatures .taskFeatureBlk.tfBlk02 {
    left: 2%;
}
.taskFeatures .taskFeatureBlk.tfBlk02,
.taskFeatures .taskFeatureBlk.tfBlk04 {
    top: 45%;
}
.taskFeatures .taskFeatureBlk.tfBlk03,
.taskFeatures .taskFeatureBlk.tfBlk04 {
    right: 8px;
}
.taskFeatures .taskFeatureBlk.tfBlk04 {
    right: 2.5%;
    top: 45%;
}

}
@media only screen and (max-width: 1200px) {
.featuresOuter .featureBlock {
    margin-bottom: 30px;
}
.businessBanner:after {
    height: 60px;
}
.taskFeatures {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.taskFeatures h4 {
    font-size: 20px;
}
.taskFeatures .taskFeatureBlk {
    position: inherit;
    top: 0 !important;
    transform: none !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
}
.tfMainCntnt {
    margin-top: 30px;
}
.featuresInfo:before {
    height: 60px;
}
.helpCntnt h2 {
    font-size: 22px;
}
.helpFeatures .impo-list li b {
    font-size: 15px;
}
.helpFeatures  .impo-list .fa.fa-check-circle {
    font-size: 17px;
}
.impo-list li {
    margin-bottom: 4px;
}
.tfMainCntnt .whiteBtn {
    font-size: 17px;
}

}
@media only screen and (max-width: 992px) {
.bBannerCntnt {
    padding-top: 80px;
}
.businessBannerInr h2 {
    font-size: 24px;
	margin-bottom: 25px;
}
.subHeading {
    font-size: 16px;
}
.businessBannerInr p br {
	display: none;
}
.businessBannerInr p {
	font-size: 14px;
}
.btn.whiteBtn {
    padding: 12px 25px;
    font-size: 14px;
    width: 150px;
}
.featureIcon img {
    height: 37px;
}
.featureBlock p {
    margin: 10px 0 0;
}
.taskFeatures .taskFeatureBlk {
    margin: auto auto 20px;
}
.helpCntnt {
    padding-left: 15px;
}

}
@media only screen and (max-width: 767px) {
.businessBannerInr h2 br,
.subHeading br {
	display: none;
}
.featuresInfo:after {
    bottom: 192px;
    height: 80px;
}
.featuresInfo:before {
    height: 45px;
}
.tfMainCntnt .whiteBtn {
    margin-top: -42px;
}
.businessBanner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a35af;
    opacity: 0.4;
}
.bBannerCntnt {
    z-index: 999;
}
.bannerAiOuter {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    opacity: 0.4;
}

}
@media only screen and (max-width: 640px) {
.featuresInfo:before {
    bottom: 192px;
}
.featuresInfo:after {
    bottom: 112px;
}

}
@media only screen and (max-width: 480px) {
.helpFeatures  .impo-list li {
    width: 100%;
    margin-bottom: 0px;
}
.helpCntnt h2 {
    font-size: 18px;
    margin-top: 20px;
}
.helpCntnt p {
    margin-bottom: 20px;
}

}