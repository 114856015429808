$max-tab-count: 5;
$tab-wrap-border-radius: 6px;
.tab-wrap {
  transition: 0.3s box-shadow ease;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  list-style: none;
}

.tabs {
  display: flex;
  width: 100%;
  margin-bottom: 0;
}

.tabs label {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #fff;
  flex-grow: 1;
  text-align: center;
  background-color: #060F21;
  user-select: none;
  transition: 0.3s background-color ease, 0.3s box-shadow ease;
  height: 50px;
  padding: 15px;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-bottom: 2px solid #10182A;

  &:hover {
    background-color: #10182A;
  }

  &.active {
    background-color: #10182A;
    color: #fff;
    border-bottom: 2px solid #94c23c;
  }
}

.tab__content {
  padding: 10px 25px;
  background-color: #060f21;
  color: #ffffff;
  width: 100%;
  left: 0;
  border-radius: 0 0 6px 6px;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  top: 0;

  &.active {
    display: block;
    opacity: 1;
    transform: translateY(0px);
  }
}
